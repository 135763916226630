import React, { useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './Gallery.css';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from '../api/photos';
 
    export default function ZahGallery() { 
      const [currentImage, setCurrentImage] = useState(0);
      const [viewerIsOpen, setViewerIsOpen] = useState(false);
    
      const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

        return(
          <section id="gallery">
            <Container>
              <Container className="remove-inherited-pl-pr">
                <Fade distance="20px" duration={4000} bottom>
                <Gallery photos={photos} onClick={openLightbox} />
                </Fade>
                  <ModalGateway>
                    {viewerIsOpen ? (
                      <Modal onClose={closeLightbox}>
                        <Carousel
                          currentIndex={currentImage}
                          views={photos.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
              </Container>
            </Container>
          </section>
        );
}