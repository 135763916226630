var Menus = [
    {
        typeOne: "Lunch Set Menu",
        typeOneDescription: "Our Lunch Set Menu is available from Tuesday to Friday from 12noon to 2:30pm.",
        typeTwo: "Lunch A La Carte Menu",
        typeTwoDescription: "Our Lunch A La Carte Menu is available Tuesday to Friday from 12noon to 2:30pm.",
        typeThree: "Dinner Sharing Menu",
        typeThreeDescription: "Our Dinner Sharing Menu is available Tuesday to Saturday featuring a generous spread of authentic, robust and colourful Lebanese mezze dishes (both hot and cold). We strive to include a wide array of both vegetarian and meat selections ensuring the freshest seasonal produce is used. It is also served with complimentary Lebanese pickles, freshly baked Lebanese bread, and scrumptious daily Lebanese dessert accompanied by traditional Lebanese coffee or freshly brewed mint tea.",
        typeThreeOptions: {
            optionOne: {
                name: "Arbaa Menu",
                description: "A selection of 4 dishes from our Sharing Menu"
            },
            optionTwo: {
                name: "Setteh Menu",
                description: "A selection of 6 dishes from our Sharing Menu"
            },
            optionThree: {
                name: "Thamaniah Menu",
                description: "A selection of 8 dishes from our Sharing Menu"
            },
            optionFour: {
                name: "Ashra Menu",
                description: "A selection of 10 dishes from our Sharing Menu"
            }
        },
        typeFour: "Dinner A La Carte Menu",
        typeFourDescription: "Our Dinner A La Carte Menu is available from Tuesday to Saturday.",
        typeFive: "Takeaway Menu",
        typeFiveDescription: "Our Takeaway Menu can be viewed via Deliveroo.",
        typeSix: "Events & Catering Menu",
        typeSixDescription: "For all your event and catering needs, please email us.",
        typeOneCategoryOne: "Executive Set",
        typeOneCategoryTwo: "Express Set",
        typeTwoCategoryOne: "Cold Mezze",
        typeTwoCategoryTwo: "Hot Mezze",
        coldMezze: {
            choiceOne: {
                name: "Hummus",
                description: "Traditional well-loved chickpea dip infused with tahini and lemon juice",
                note: "Served with Lebanese Bread"
            },
            choiceTwo: {
                name: "Jos Mahrouse",
                description: "Fiery house speciality dip made with mixed Mediterranean spicy nuts",
                note: "Served with Lebanese Bread"
            },
            choiceThree: {
                name: "Fatoush",
                description: "Crispy salad of fresh lettuce, tomato, onion and croutons with a fruity sumac based dressing"
            },
            choiceFour: {
                name: "Lebanese House Salad",
                description: "Ever-popular Middle Eastern herb pizza made with Zaa'taar (thyme)"
            },
            choiceFive: {
                name: "Babaganouj",
                description: "Ever-popular Middle Eastern herb pizza made with Zaa'taar (thyme)"
            },
            choiceSix: {
                name: "Tabouleh",
                description: "Ever-popular Middle Eastern herb pizza made with Zaa'taar (thyme)"
            }
        },
        hotMezze: {
            choiceOne: {
                name: "Fattayer",
                description: "Pastry parcels of sautéed spinach and pine nuts with a hint of pomegranate"
            },
            choiceTwo: {
                name: "Felafel",
                description: "Crispy vegetarian croquettes of chickpeas and fava beans served with tahini sauce"
            },
            choiceThree: {
                name: "Kafta",
                description: "Tender grilled skewers of minced lamb with parsley, onion, and Lebanese spices"
            },
            choiceFour: {
                name: "Vegetarian Safiha",
                description: "Tangy spinach mix enclosed in crispy oven roasted pita bread"
            },
            choiceFive: {
                name: "Vegetarian Fousulieh",
                description: "Traditional bean stew served with Lebanese rice"
            },
            choiceSix: {
                name: "Chicken Elmina",
                description: "Juicy pieces of chicken marinated in a refreshing, tangy, mint sauce"
            },
            choiceSeven: {
                name: "Shish Taouk",
                description: "Traditional grilled chicken skewers, in a lemon yoghurt marinade"
            },
            choiceEight: {
                name: "Lamb Kebabs",
                description: "Traditionally grilled Lebanese lamb kebabs, lightly spiced"
            },
            choiceNine: {
                name: "Mankoush",
                description: "Ever-popular Middle Eastern herb pizza made with Zaa'taar (thyme)"
            },
            choiceTen: {
                name: "Haloumi",
                description: "Haloumi cheese lightly grilled and served with a drizzling of zaatar dressing"
            },
            choiceEleven: {
                name: "Kibbe",
                description: "Plump lamb patties with an exotic blend of pine nuts, minced lamb and cracked wheat"
            },
            choiceTwelve: {
                name: "Soujuk",
                description: "Home-made Lebanese mini-sausages, generously spiced"
            },
            choiceThirteen: {
                name: "Pan-fried Aubergine",
                description: "Delicately spiced slices of aubergine, lightly fried"
            },
            choiceFourteen: {
                name: "Safiha",
                description: "Beautifully seasoned lamb mince enclosed in crispy, oven roasted pita bread"
            },
            choiceFifteen: {
                name: "Batata Harra",
                description: "Traditional Lebanese spicy potatoes, fried with chilli, coriander and garlic"
            },
            choiceSixteen: {
                name: "Fousulieh with Lamb",
                description: "Hearty, full-bodied Lebanese lamb and bean stew, served with Lebanese rice"
            },
            choiceSeventeen: {
                name: "Fish Kebabs",
                description: "Carefully grilled spiced fish kebabs"
            },
            choiceEighteen: {
                name: "Arnabit Batata b’il Tahini",
                description: "Delicately seasoned cauliflower florets and spiced potatoes in a rich tahini sauce"
            },
            choiceNineteen: {
                name: "Samke Harra",
                description: "Satiny fish fillets baked in a sharp and spicy tahini based sauce"
            }
        },
        specialtyAddOns: {
            choiceOne: {
                name: "Prawn Sidon",
                description: "Bite-sized prawns sautéed in a robust chilli sauce with tomatoes"
            },
            choiceTwo: {
                name: "Phoenican Shrimp Sauté",
                description: "Shrimp sautéed in a tangy lemon based sauce with parsley"
            },
            choiceThree: {
                name: "Kibbe Nayyeh",
                description: "A rustic, authentic Lebanese dish of raw minced lamb, cracked wheat and spices, the pride of Lebanon",
                note: "To be preordered at the time of booking"
            },
            choiceFour: {
                name: "Kibbe b'il Laban",
                description: "Mini kibbe balls cooked ever-so-slowly in a creamy yoghurt base, served with Lebanese rice"
            } 
        },
        typeOneDishes: {
            main:
                {
                    name: "Zahrabel Mezze Sampler",
                    price: "$150",
                    description: "Hummus, Jos Mahrouse, House Salad, and Felafel served with Lebanese pickles and Lebanese bread"
                },
            choiceOne: 
                {
                    name: "Vegetarian Safiha",
                    price: "$178",
                    description: "Tangy spinach mix enclosed in crispy oven roasted pita bread"
                },
            choiceTwo:
                {
                    name: "Vegetarian Fousulieh",
                    price: "$188",
                    description: "Traditional bean stew served with Lebanese rice"
                },
            choiceThree:
                {
                    name: "Kafta",
                    price: "$188",
                    description: "Grilled skewers of minced lamb with parsley, onion, and spices"
                },
            choiceFour:
                {
                    name: "Chicken Elmina",
                    price: "$190",
                    description: "Juicy pieces of chicken marinated in a refreshing, tangy, mint sauce"
                },
            choiceFive:
                {
                    name: "Shish Taouk",
                    price: "$248",
                    description: "Succulent grilled chicken skewers, served with garlic sauce"
                },
            choiceSix:
                {
                    name: "Lamb Kebabs",
                    price: "$268",
                    description: "Delicately spiced lamb kebabs, served with garlic sauce"
                },
            
            dessert: 
                {
                    name: "Dessert of the Day",
                    description: "Served with Lebanese Coffee or Hot Mint Tea"
                }
        
        }
    }
]

export default Menus;