import React, { Component } from 'react';
import { Container, Row, Col, Toast , ToastBody , ToastHeader } from 'react-bootstrap';
import './FindUs.css';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faTripadvisor } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';

export default class FindUs extends Component {
    static defaultProps = {
        center: {
            lat: 22.278031,
            lng: 114.175982
        },
        zoom: 19
    }
    
    render() {
        return(
            <section id="findus">
            <Fade distance="15px" duration={3000} top>
                <Container className="text-center">
                    <h1>Find Us</h1>
                </Container>
                <Container className="text-center space-b-30">
                    <p>Our Contact Details</p>
                </Container>
                {/* 
                <Container className="text-center space-b-30">
                    <p>25th Floor, 239 Hennessy Road, Wanchai, Hong Kong</p>
                </Container>
                */}
            </Fade>
                {/* 
                <Container>
                <Fade distance="15px" duration={2000} top>
                <Container id="map" className="google-map-react remove-inherited-pl-pr space-b-30">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="300px" id="gmap_canvas" src="https://maps.google.com/maps?q=25th%20Floor,%20239%20Hennessy%20Road,%20Wanchai,%20Hong%20Kong&t=&z=19&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        <a href="https://fmovies2.org"></a>
                    </div>
                </Container>
                </Fade>
                */}
                <Container id="contact-container" className="remove-inherited-pl-pr">
                        <Fade distance="25px" duration={1500} left>
                        <Toast className="space-b-30 text-center">
                                <ToastBody>
                                    <p>Email: info@zahrabel.com</p>
                                    <Container className="space-b-30">
                                            <Col className="padding-b-1">
                                                <a className="icon-link" href="https://www.instagram.com/zahrabel_hk/" rel="noopener noreferrer" target="_blank">
                                                    <FontAwesomeIcon className="fa-3x" icon={faInstagram} />
                                                </a>
                                            </Col>
                                            <Col>
                                                <a className="icon-link" href="https://www.facebook.com/Zahrabel.HongKong/" rel="noopener noreferrer" target="_blank">
                                                    <FontAwesomeIcon className="fa-3x"icon={faFacebook} />
                                                </a>
                                            </Col>
                                    </Container>
                                </ToastBody>
                            </Toast>
                        </Fade>
                </Container>
            </section>
        );
    }  
}