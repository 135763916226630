import React, { Component } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './Membership.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class Membership extends Component {
    state = {
        showMembershipModal: false
    }

    showMembershipModal = () => {
        this.setState({
            showMembershipModal: true
        });
    }

    hideMembershipModal = () => {
        this.setState({
            showMembershipModal: false
        });
    }

    handleMembershipModalSubmit = () => {
        this.setState({
            showMembershipModal: false
        });    
    }

    renderModal() {
        const showMembershipModal = this.state.showMembershipModal;
        return(
            <Modal size="lg" show={showMembershipModal} onHide={this.hideMembershipModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                     <iframe id="frame" title="reservations" src="https://services.zahrabel.com/webservice-booking/zahrabel"></iframe>
                </Modal.Body>
            </Modal>
        );
    }      
    
   render() {
       return(
           <section id="membership">
              {this.renderModal()}
            <Fade distance="15px" duration={3000} top>
                <Container className="text-center">
                    <h1>Membership &amp; Reservations</h1>
                </Container>
                <Container className="text-center dark-background-text">
                    <p>Zahrabel Dining Club is a members-only, fully licensed club providing our members with a unique dining experience. Please note that membership is without charge and registration is available upon arrival at Zahrabel.</p>
                    <p>For reservations, please <AnchorLink className="no-decoration zahra-color" href="#findus">call or email us</AnchorLink>.</p>
                </Container>
            </Fade>
            </section>
       );
   }  
}
