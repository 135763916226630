import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './Concept.css';
import imageOne from '../img/OldZahra.jpg';
import imageTwo from '../img/Zahrabel-Interior_Website200.jpg';


export default class Concept extends Component {
    render() { 
        return (
            <section id="concept">
                <Container>
                <Fade distance="15px" duration={3000} top>
                    <Container className="text-center">
                        <h1>Concept</h1>
                    </Container>
                    <Container className="text-center space-b-30">
                        <p>Zahrabel is a fully licensed members-only dining club offering a unique culinary experience to our members.</p>
                    </Container>
                </Fade>
                    <Container className="remove-inherited-pl-pr">
                        <Row>
                            <Col sm>
                            <Fade distance="20px" duration={2000} bottom>
                                <Container className="text-center concept-image" style={{'background': 'url('+ imageOne +') center', 'background-size': 'cover','background-repeat': 'no-repeat', 'height': '200px'}}>

                                </Container>
                            </Fade>
                            <Fade distance="20px" duration={3000} bottom>
                                <Container className="text-center">
                                    <h3>Ever-growing family legacy</h3>
                                </Container>
                                <Container className="remove-inherited-pl-pr mobile-space">
                                    <p className="text-justify">Zahrabel is an homage to Zahra Restaurant (1993-2006), the original pioneer of Lebanese cuisine in Hong Kong.  Zahra introduced Hong Kong diners to our signature El-Mahmoud family recipes and Zahrabel is proud to be carrying on this legacy, where each dish is cooked with love and pride.</p>
                                </Container>
                            </Fade>
                            </Col>
                            <Col sm>
                            <Fade distance="20px" duration={2000} bottom>
                                <Container className="text-center concept-image" style={{'background': 'url('+ imageTwo +') center', 'background-size': 'cover','background-repeat': 'no-repeat', 'height': '200px'}}>

                                </Container>
                            </Fade>
                            <Fade distance="20px" duration={3000} bottom>
                                <Container className="text-center">
                                    <h3>An oasis in the heart of Wanchai</h3>
                                </Container>
                                <Container className="remove-inherited-pl-pr mobile-space">
                                    <p className="text-justify">A rarity on the Hong Kong dining scene, Zahrabel encourages its members to wine and dine in an exceptionally relaxed and warm setting. Our traditional mezze and popular Sharing Menus are designed to highlight the joys of communal dining which is at the core of Lebanese food and culture.</p>
                                </Container>
                            </Fade>
                            </Col>
                        </Row>
                    </Container>

                </Container>
            </section>
        );
    }
}