import React, { Component } from 'react';
import Hero from './Hero';
import Membership from './Membership';
import Menu from './Menu';
import FindUs from './FindUs';
import ZahGallery from './Gallery';
import News from './News';
import Footer from './Footer';
import NavBar from './NavBar';
import Concept from './Concept';

export default class Home extends Component {
    state = {

    }

    render() { 
        return (
            <>
            <NavBar />
            <Hero />
            <Concept />
            {/* 
            <Membership />
            <Menu />
            */}
            <ZahGallery />
            <News />
            <FindUs />
            <Footer />
            </>
        );
    }
}