import React, { Component } from 'react';
import './News.css';
import { Container, Row, Col } from 'react-bootstrap';
import ReviewOne from '../img/PressQuote-New-01.jpg';
import ReviewTwo from '../img/PressQuote-New-02.jpg';
import ReviewThree from '../img/PressQuote-New-03.jpg';
import ReviewFour from '../img/PressQuote-New-04.jpg';
import ReviewFive from '../img/PressQuote-New-05.jpg';
import ReviewSix from '../img/PressQuote-New-06.jpg';
import ReviewSeven from '../img/PressQuote-New-07.jpg';
import ReviewEight from '../img/PressQuote-New-08.jpg';
import ReviewNine from '../img/PressQuote-New-09.jpg';
import ReviewTen from '../img/PressQuote-New-10.jpg';
import ReviewEleven from '../img/PressQuote-New-11.jpg';
import Fade from 'react-reveal/Fade';
/* import ReviewTwelve from '../img/PressQuote-New-12.jpg'; */

export default class News extends Component {
    render() {
        return(
            <section id="news">
                <Container>
                <Fade distance="15px" duration={3000} top>
                    <Container className="text-center">
                        <h1>In The News</h1>
                    </Container>
                    <Container className="text-center dark-background-text space-b-30">
                        <p>What others say about us</p>
                    </Container>
                </Fade>
                    <Container>
                        <Fade distance="15px" duration={2000} left>
                            <Row>
                                <Col sm>
                                    <a href="https://www.sassyhongkong.com/zahrabel-hong-kong-eat/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewOne} alt="Gafencu Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    <a href="https://www.hkyantoyan.com/drink-dine/zahrabel-lebanese-dining" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewTwo} alt="Crave Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    <a href="https://www.lifestyleasia.com/hk/food-drink/dining/hong-kong-best-middle-eastern-eats-food-restaurants/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewThree} alt="Food Panda Review on Zahrabel"></img>
                                    </a>
                                </Col> 
                            </Row>
                        </Fade>
                        <Fade distance="15px"  duration={2000} right>
                            <Row>
                                <Col sm>
                                    <a href="https://www.scmp.com/magazines/48hrs/article/1407776/another-fine-mezze-zahrabel-wan-chai" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewFour} alt="Lifestyle Asia Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    <a href="https://www.afoodieworld.com/foodie/3218-17-foods-that-will-change-your-life-for-under-100" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewFive} alt="Sassy Hong Kong Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    <a href="https://coconuts.co/hongkong/food-drink/desirable-zahrabel-hong-kongs-most-refined-lebanese-restaurant/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewSix} alt="Coconuts Hong Kong Review on Zahrabel"></img>
                                    </a>
                                </Col> 
                            </Row>
                        </Fade>
                        <Fade distance="15px"  duration={2000} left>
                            <Row>
                                <Col sm>
                                    <a href="https://www.afoodieworld.com/foodie/3370-tried-and-tasted-zahrabel-nbsp" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewSeven} alt="Foodie Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    <a href="https://www.igafencu.com/r/lebaneat-2/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewEight} alt="Smart Travel Asia Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    <a href="https://socialveganhk.com/zahrabeldiningclub" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewNine} alt="Dim Sum Diaries Review on Zahrabel"></img>
                                    </a>
                                </Col> 
                            </Row>
                        </Fade>
                        <Fade distance="15px"  duration={2000} right>
                            <Row>
                                <Col sm>
                                    <a href="https://thehoneycombers.com/hong-kong/halal-restaurants-in-hong-kong/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewTen} alt="Hkyantoyan Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    <a href="http://thedimsumdiaries.com/2014/02/06/zahrabel-lebanese-private-dining-club-hong-kong/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewEleven} alt="Fore Girls Review on Zahrabel"></img>
                                    </a>
                                </Col>

                                <Col sm>
                                    {/*
                                    <a href="" target="_blank" rel="noopener noreferrer">
                                        <img className="img-fluid" src={ReviewTwelve} alt="Foodie Review on Zahrabel"></img>
                                    </a>
                                    */}
                                </Col> 
                            </Row>
                        </Fade>
                    </Container> 
            </Container>
            </section>
        );
    }  
}