import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import HeroImage from '../img/website-landingpage-closure-16.jpg';
import LazyHero from 'react-lazy-hero';
import Fade from 'react-reveal/Fade';

export default class Hero extends Component {

    render() {
        return(
            <div>
                <LazyHero
                imageSrc={HeroImage}
                minHeight='100vh'
                color="#000"
                opacity="0.35"
                parallaxOffset="100"
                transitionDuration="600"
                >
                    <Fade distance="25px" duration={2000} top>
                    <Container>
                        <Container id="welcome" className="text-center welcome-message">
                            <h1 className="zahrabel-gold title text-left">Thank you</h1>
                            <p className="offwhite-text space text-left lander-p">After almost 30 years, our family has decided to take some time away from F&B and focus on other ventures. We bid you a fond farewell and say a huge thank you to all our patrons for your amazing, enduring support over the years. We could not have hoped for a better farewell and have made beautiful memories that we will hold dear forever!</p>
                            <p className="offwhite-text text-left lander-p">Till we meet again, best wishes from our Zahrabel family to yours.</p>
                        </Container>
                    </Container>
                    </Fade>
                </LazyHero>
            </div>
        );
    }
    
}

