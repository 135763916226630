export const photos = [
    {
      src: require("../img/ZbelWebGallery-1.jpg"),
      width: 4,
      height: 3
    },
    {
      src: require("../img/ZbelWebGallery-2.jpg"),
      width: 3,
      height: 4
    },
    {
      src: require("../img/ZbelWebGallery-3.jpg"),
      width: 5,
      height: 4
    },
    {
      src: require("../img/ZbelWebGallery-4.jpg"),
      width: 3,
      height: 4
    },
    {
      src: require("../img/ZbelWebGallery-5.jpg"),
      width: 5,
      height: 4
    },
    {
      src: require("../img/ZbelWebGallery-6.jpg"),
      width: 4,
      height: 3
    },
    {
      src: require("../img/ZbelWebGallery-8.jpg"),
      width: 4,
      height: 3
    },
    {
      src: require("../img/ZbelWebGallery-7.jpg"),
      width: 3,
      height: 4
    },
    {
      src: require("../img/ZbelWebGallery-9.jpg"),
      width: 16,
      height: 10
    },
    {
      src: require("../img/ZbelWebGallery-10.jpg"),
      width: 4,
      height: 3
    },
    {
      src: require("../img/ZbelWebGallery-11.jpg"),
      width: 3,
      height: 2
    },
    {
      src: require("../img/ZbelWebGallery-12.jpg"),
      width: 5,
      height: 4
    }
  ];
  