import React, { Component } from 'react';
import { Container, Row, Col, Button, Accordion, Card } from 'react-bootstrap';
import './Menu.css';
import Menus from '../api/menus';
import ZahLunchMenu from '../docs/Zahrabel_Lunch_Menu_2019.jpg';
import ZahALaCarteLunch from '../docs/Zahrabel_LunchAlaCarte_Menu_2019.jpg';
import ZahDinnerSharingMenu from '../docs/Zahrabel_DinnerSharing_Menu_2019.jpg';
import ZahALaCarteDinner from '../docs/Zahrabel_DinnerAlaCarte_Menu_2019.jpg';
import Fade from 'react-reveal/Fade';

export default class ZahrabelMenus extends Component {

    renderLunchMenus() {
        let LunchMenu = Menus;

        const ZahrabelLunchMenu = ({ typeOne, typeTwo, typeThree, typeFour, typeFive, typeSix, typeOneDescription, typeTwoDescription, typeThreeDescription, typeFourDescription, typeFiveDescription, typeSixDescription, typeThreeOptions, typeOneCategoryOne, typeOneCategoryTwo, typeTwoCategoryOne, typeTwoCategoryTwo, typeOneDishes, coldMezze, hotMezze, specialtyAddOns, dessert }) => {
            return(
                <div>
                <Container>
                <Container className="remove-inherited-pl-pr">
                
{/* ----------------------- Lunch Set Menu Accordion ----------------------------------  */}
            <Fade distance="20px" duration={2000} bottom>
                <Accordion>
                    {/* 
                    <Card className="rounded-0">
                        <Card.Header className="text-left">
                        <Accordion.Toggle className="no-text-decoration hover-text-gold" as={Button} variant="link" eventKey="0">
                            <Container className="text-left">
                                <table className="table">
                                    <tr>
                                        <th scope="col"><h2 className="gold">{typeOne}</h2></th>
                                        <th scope="col" className="text-right"><h2>+</h2></th>
                                    </tr>
                                </table>
                            </Container>
                        </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            <Container className="text-left">
                                    <Row>
                                        <Col sm={9}>
                                            <p className="">{typeOneDescription}</p>
                                        </Col>
                                        <Col sm={3}>
                                            <a href={ZahLunchMenu} target="_blank" rel="noopener noreferrer">
                                                <Button className="menu-button btn-sm">
                                                    Download
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    */}
{/* ------------------------- A La Carte Lunch Accordion ------------------------------ 

                    <Card className="rounded-0">
                        <Card.Header className="text-left">
                        <Accordion.Toggle className="no-text-decoration" as={Button} variant="link" eventKey="1">
                            <Container className="text-left">
                                <table className="table">
                                    <tr>
                                        <th scope="col"><h2>{typeTwo}</h2></th>
                                        <th scope="col" className="text-right"><h2>+</h2></th>
                                    </tr>
                                </table>
                                </Container>
                        </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                            <Container className="text-left">
                                    <Row>
                                        <Col sm={9}>
                                            <p className="">{typeTwoDescription}</p>
                                        </Col>
                                        <Col sm={3}>
                                            <a href={ZahALaCarteLunch} target="_blank" rel="noopener noreferrer">
                                                <Button className="menu-button btn-sm">
                                                    Download
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    */}
{/* ----------------------------- Sharing Menu Accordion ---------------------------- */}
                    <Card className="rounded-0">
                        <Card.Header className="text-left">
                        <Accordion.Toggle className="no-text-decoration" as={Button} variant="link" eventKey="0">
                            <Container className="text-left">
                                    <table className="table">
                                        <tr>
                                            <th scope="col"><h2>{typeThree}</h2></th>
                                            <th scope="col" className="text-right"><h2>+</h2></th>
                                        </tr>
                                    </table>
                            </Container>
                        </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            <Container className="text-left">
                                    <Row>
                                        <Col sm={9}>
                                            <p className=" text-justify">{typeThreeDescription}</p>
                                        </Col>
                                        <Col sm={3}>
                                            <a href={ZahDinnerSharingMenu} target="_blank" rel="noopener noreferrer">
                                                <Button className="menu-button btn-sm">
                                                    Download
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

{/* ---------------------------- A La Carte Dinner Accordion ------------------------- */}
                    <Card className="rounded-0">
                        <Card.Header className="text-left">
                        <Accordion.Toggle className="no-text-decoration" as={Button} variant="link" eventKey="1">
                            <Container className="text-left">
                                    <table className="table">
                                        <tr>
                                            <th scope="col"><h2>{typeFour}</h2></th>
                                            <th scope="col" className="text-right"><h2>+</h2></th>
                                        </tr>
                                    </table>
                            </Container>
                        </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                            <Container className="text-left">
                                    <Row>
                                        <Col sm={9}>
                                            <p className="">{typeFourDescription}</p>
                                        </Col>
                                        <Col sm={3}>
                                            <a href={ZahALaCarteDinner} target="_blank" rel="noopener noreferrer">
                                                <Button className="menu-button btn-sm">
                                                    Download
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

    {/* ---------------------------- Takeaway Accordion ------------------------- */}

                    <Card className="rounded-0">
                        <Card.Header className="text-left">
                        <Accordion.Toggle className="no-text-decoration" as={Button} variant="link" eventKey="2">
                            <Container className="text-left">
                                    <table className="table">
                                        <tr>
                                            <th scope="col"><h2>{typeFive}</h2></th>
                                            <th scope="col" className="text-right"><h2>+</h2></th>
                                        </tr>
                                    </table>
                            </Container>
                        </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                            <Container className="text-left">
                                    <Row>
                                        <Col sm={9}>
                                            <p className="">{typeFiveDescription}</p>
                                        </Col>
                                        <Col sm={3}>
                                            <a href="https://deliveroo.hk/en/menu/hong-kong/wanchai/zahrabel-dining-club" target="_blank" rel="noopener noreferrer">
                                                <Button className="menu-button btn-sm">
                                                    View
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

    {/* ---------------------------- Events and Catering Accordion ------------------------- */}

                    <Card className="rounded-0">
                        <Card.Header className="text-left">
                        <Accordion.Toggle className="no-text-decoration" as={Button} variant="link" eventKey="3">
                            <Container className="text-left">
                                    <table className="table">
                                        <tr>
                                            <th scope="col"><h2>{typeSix}</h2></th>
                                            <th scope="col" className="text-right"><h2>+</h2></th>
                                        </tr>
                                    </table>
                            </Container>
                        </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                            <Container className="text-left">
                                    <Row>
                                        <Col sm={9}>
                                            <p className="">{typeSixDescription}</p>
                                        </Col>
                                        <Col sm={3}>
                                            <a href="mailto:info@zahrabel.com?" Subject="Events and Catering Menu" target="_top" rel="noopener noreferrer">
                                                <Button className="menu-button btn-sm">
                                                    Email
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                </Fade>
                </Container>
                </Container>
            </div>
            )
        }
        return(
            <>
                {LunchMenu.map(
                    (LunchMenu , i ) =>
                    <ZahrabelLunchMenu
                    typeOne={LunchMenu.typeOne}
                    typeTwo={LunchMenu.typeTwo}
                    typeThree={LunchMenu.typeThree}
                    typeFour={LunchMenu.typeFour}
                    typeFive={LunchMenu.typeFive}
                    typeSix={LunchMenu.typeSix}
                    typeOneDescription={LunchMenu.typeOneDescription}
                    typeTwoDescription={LunchMenu.typeTwoDescription}
                    typeThreeDescription={LunchMenu.typeThreeDescription}
                    typeFourDescription={LunchMenu.typeFourDescription}
                    typeFiveDescription={LunchMenu.typeFiveDescription}
                    typeSixDescription={LunchMenu.typeSixDescription}
                    typeThreeOptions={LunchMenu.typeThreeOptions}
                    typeOneCategoryOne={LunchMenu.typeOneCategoryOne}
                    typeOneCategoryTwo={LunchMenu.typeOneCategoryTwo}
                    typeTwoCategoryOne={LunchMenu.typeTwoCategoryOne}
                    typeTwoCategoryTwo={LunchMenu.typeTwoCategoryTwo}
                    typeOneDishes={LunchMenu.typeOneDishes}
                    coldMezze={LunchMenu.coldMezze}
                    hotMezze={LunchMenu.hotMezze}
                    specialtyAddOns={LunchMenu.specialtyAddOns}
                    dessert={LunchMenu.dessert}
                    />
                )}
            </>
        )
    }
    
    render() {
        return(
            <section id="menus">
            <Fade distance="15px" duration={3000} top>
                <Container className="text-center">
                    <h1>Menu</h1>
                </Container>
                <Container className="text-center">
                    <p>All of our menu items feature our authentic signature family recipes.</p>
                </Container>
            </Fade>
                {this.renderLunchMenus()}
            </section>
        );
    }  
}