import React, { Component } from "react";
import './NavBar.css';
import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { Navbar, NavbarBrand, Nav, NavItem } from "react-bootstrap";
import Logo from '../img/zahrabel_logo.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Fade from 'react-reveal/Fade';

export default class NavBar extends Component {
    state = { 
      isAuthenticated: false,
      isAuthenticating: true,
      collapse: false,
      status: 'Closed' 
    };

    onEntering = () => {
      this.setState({ status: 'Opening...' });
    }
  
    onEntered = () => {
      this.setState({ status: 'Opened' });
    }
  
    onExiting = () => {
      this.setState({ status: 'Closing...' });
    }
  
    onExited = () => {
      this.setState({ status: 'Closed' });
    }
  
    toggle = () => {
      this.setState(state => ({ collapse: !state.collapse }));
    }  
  
  render() {
    
    return (

      <div>
          <Navbar id="nav" className="navbar-inverse bg-inverse" expand="md">
            <Fade distance="15px" duration={3000} left>
              <NavbarBrand>
                <Container>
                <Link to="/">
                    <img className="logo" src={Logo} alt="Zahrabel Logo" />
                </Link>
                </Container>
              </NavbarBrand>
            </Fade>
            <Fade distance="10px" duration={3000} right>
              <Navbar.Toggle className="custom-toggler" onClick={this.toggle}>

              </Navbar.Toggle>
            </Fade>
            <Navbar.Collapse 
              isOpen={this.state.collapse}
              onEntering={this.onEntering}
              onEntered={this.onEntered}
              onExiting={this.onExiting}
              onExited={this.onExited}
            >
                <Nav id="nav-links" className="ml-auto" navbar>

                  <Fade distance="15px" duration={500} top>
                    <NavItem>
                      <AnchorLink className="no-decoration" href="#concept">
                        <Nav.Link className="navbar-link">Concept</Nav.Link>
                      </AnchorLink>
                    </NavItem>
                  </Fade>
                  {/* 
                  <Fade distance="15px" duration={750} top>
                    <NavItem>
                      <AnchorLink className="no-decoration" href="#membership">
                        <Nav.Link className="navbar-link">Reservations</Nav.Link>
                      </AnchorLink>
                    </NavItem>
                    </Fade>

                  <Fade distance="15px" duration={1000} top>
                    <NavItem>
                      <AnchorLink className="no-decoration" href="#menus">
                        <Nav.Link className="navbar-link">Menu</Nav.Link>
                      </AnchorLink>
                    </NavItem>
                  </Fade>
                  */}
                  <Fade distance="15px" duration={1250} top>
                    <NavItem>
                      <AnchorLink className="no-decoration" href="#gallery">
                        <Nav.Link className="navbar-link">Gallery</Nav.Link>
                      </AnchorLink>
                    </NavItem>
                  </Fade>

                  <Fade distance="15px" duration={1500} top>
                    <NavItem>
                      <AnchorLink className="no-decoration" href="#news">
                        <Nav.Link className="navbar-link">In the News</Nav.Link>
                      </AnchorLink>
                    </NavItem>
                  </Fade>

                  <Fade distance="15px" duration={1750} top>
                    <NavItem>
                      <AnchorLink className="no-decoration" href="#findus">
                        <Nav.Link className="navbar-link">Find Us</Nav.Link>
                      </AnchorLink>
                    </NavItem>
                  </Fade>

                </Nav>
            </Navbar.Collapse>
          </Navbar>
      </div>
    );
  }
}

