import React, { Component } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import './Footer.css';
import HeadShake from 'react-reveal/HeadShake';

export default class Footer extends Component {
    state = {
        showPrivacyPolicyModal: false,
        showClubRulesModal: false,
        showTermsConditionsModal: false,
        showMembershipModal: false
    }

    showPrivacyPolicyModal = () => {
        this.setState({
            showPrivacyPolicyModal: true
        });
    }

    hidePrivacyPolicyModal = () => {
        this.setState({
            showPrivacyPolicyModal: false
        });
    }

    renderPrivacyPolicyModal() {
        const showPrivacyPolicyModal = this.state.showPrivacyPolicyModal;
        return(
            <Modal size="lg" show={showPrivacyPolicyModal} onHide={this.hidePrivacyPolicyModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <h1>Privacy Policy</h1>
                    </Container>
                    <Container>
                        <p>At Zahrabel, we treat all personal information provided by members with the strictest confidentiality. We appreciate your trust that we will do so with due care and diligence in accordance with all of the relevant privacy laws of Hong Kong. Members' personal information is collected solely for the purpose of identifying Club members and ensuring conformity with our Club Rules. Members' personal data will be stored in our secured internal system and may be accessed or updated at any time through the Members' account page.</p>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
    
    showClubRulesModal = () => {
        this.setState({
            showClubRulesModal: true
        });
    }

    hideClubRulesModal = () => {
        this.setState({
            showClubRulesModal: false
        });
    }

    renderClubRulesModal() {
        const showClubRulesModal = this.state.showClubRulesModal;
        return(
            <Modal size="lg" show={showClubRulesModal} onHide={this.hideClubRulesModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container >
                        <h1>Club Rules</h1>
                    </Container>
                    <Container>
                        <p>Zahrabel operates as a dining club under Hong Kong law and the club rules set out below apply to the use of its premises.</p>
                        <p>All guests must be accompanied by Club members.</p>
                        <p>It is the responsibility of members to inform their guests of the Club's rules.</p>
                        <p>Only one non-transferable membership will be issued per member.</p>
                        <p>Membership will be reviewed annually and will be subject to the Club Rules in force at the time.</p>
                        <p>The Club reserves the right to adapt and modify its Rules from time to time.</p>
                        <p>As a courtesy to other members, members are required to keep mobile disturbances to a minimum.</p>
                        <p>Reservations must be made in advance.</p>
                        <p>Prepayment of set dinners is required for parties of 8 or above at the time of booking in order to confirm the reservation unless otherwise agreed.</p>
                        <p>The Club does not offer refunds for any cancellations. However, your prepayment may be credited towards your next reservation in the event of cancellation provided that you notify the Club of your cancellation no later than 3pm on the day of your reservation.</p>
                        <p>Members and guests are required to observe a smart casual dress code at all times.</p>
                        <p>Members who wish to reserve the entire Club premises may contact the General Manager.</p>
                        <p>The Club is not responsible for any personal property brought onto its premises.</p>
                        <p>The Club shall not serve Liquor to any persons under the age of 18 years.</p>
                        <p>Members and guests are not permitted to bring outside food and drinks onto the premises for consumption under any circumstances, with the exception of wine in which case a corkage fee applies.</p>
                        <p>Smoking is strictly prohibited inside the Club.</p>
                        <p>Young children are not permitted inside the Club.</p>
                        <p>Pets are not permitted inside the Club.</p>
                        <p>In the event of adverse weather (e.g. Typhoon No. 8 or above, or Black Rainstorm Warning), please check our website for updates regarding our opening hours.</p>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }

    showTermsConditionsModal = () => {
        this.setState({
            showTermsConditionsModal: true
        });
    }

    hideTermsConditionsModal = () => {
        this.setState({
            showTermsConditionsModal: false
        });
    }    

    renderTermsConditionsModal() {
        const showTermsConditionsModal = this.state.showTermsConditionsModal;
        return(
            <Modal size="lg" show={showTermsConditionsModal} onHide={this.hideTermsConditionsModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <h1>Zahrabel Terms and Conditions</h1>
                    </Container>
                    <Container>
                        <h3>Zahrabel Events</h3>
                    </Container>
                    <Container>
                        <p>Purchase of all tickets for Zahrabel Events are strictly non-refundable.</p>
                        <p>The Purchaser may transfer his or her ticket to another attendee and must notify Zahrabel at least 1 working day prior to the Event.</p>
                    </Container>
                    <Container>
                        <h3>Z@Home Hampers</h3>
                    </Container>
                    <Container>
                        <p>All orders shall be strictly subject to availability.</p>
                        <p>Delivery of orders shall take place from Wednesdays to Saturdays between 3pm-6pm or 6pm-9pm.</p>
                        <p>Pick-up of orders from Zahrabel Dining Club shall take place from Wednesdays to Saturdays between 6pm-9pm. Failure to pick-up orders on selected pick-up dates shall result in forfeiture of such orders.</p>
                        <p>Orders shall be completed at least 2 business days in advance of delivery date.</p>
                        <p>Orders shall be completed upon receipt of payment.</p>
                        <p>All published prices do not include delivery charges, which shall be detailed during the final checkout process.</p>
                        <p>All payments shall be received by Credit Card.</p>
                        <p>All payments received shall be non-refundable.</p>
                        <p>Cancellations shall be received within 1 business day of the delivery date.</p>
                        <p>Recipient's signature shall be required upon delivery.</p>
                        <p>Zahrabel shall accept no responsibility for incorrect delivery details provided.</p>
                        <p>All deliveries and pick-up of orders shall be suspended in the event of Typhoon 8 or Black Rainstorm Warning.</p>
                        <p>Zahrabel reserves the right to substitute goods of equal value in the event of supply difficulties.</p>
                        <p>Zahrabel reserves the right to amend the Terms and Conditions at any time.</p>
                        <p>All matters or disputes in connection with the Z@Home Takeaway Hamper range shall be subject to the final decision of Zahrabel.</p>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }

    showMembershipModal = () => {
        this.setState({
            showMembershipModal: true
        });
    }

    hideMembershipModal = () => {
        this.setState({
            showMembershipModal: false
        });
    }

    handleMembershipModalSubmit = () => {
        this.setState({
            showMembershipModal: false
        });    
    }

    renderMembershipModal() {
        const showMembershipModal = this.state.showMembershipModal;
        return(
            <Modal id="inhouse-membership" size="lg" show={showMembershipModal} onHide={this.hideMembershipModal}>
                <Modal.Body>
                    <Container className="remove-inherited-pl-pr remove-inherited-ml-mr">
                        <h1>Membership</h1>
                        <p>Zahrabel Dining Club is a members-only, fully licensed club providing our members with a unique dining experience. You can become a Zahrabel Dining Club Member by simply registering with us via Ookus using the app below. Please note that membership is without charge.</p>
                    </Container>
                <iframe id="frame" className="iframe-fullscreen" title="membership-footer" src="https://services.zahrabel.com/webservice-booking/zahrabel"></iframe>
                </Modal.Body>
            </Modal>
        );
    } 

    render() {
        return(
        <section id="footer">
            {this.renderMembershipModal()}
            {this.renderPrivacyPolicyModal()}
            {this.renderClubRulesModal()}
            {this.renderTermsConditionsModal()}
            <HeadShake duration={2000}>
                <Container fluid className="text-center">
                    <p className="zahrabel-gold">Zahrabel Dining Club <Button className="inhouse-membership-copyright" onClick={this.showMembershipModal}>&copy;</Button> 2013 - 2020</p>
                    <p className="zahrabel-gold"><button className="policies" onClick={this.showPrivacyPolicyModal}>Privacy Policy</button> | <button className="policies" onClick={this.showClubRulesModal}>Club Rules</button> {/* | <button className="policies" onClick={this.showTermsConditionsModal}>Terms &amp; Conditions</button>*/}</p>
                    <a className="yelleroo-signature" target="_blank" rel="noopener noreferrer" href="https://yelleroo.com">Agile Serverless Development by Yelleroo</a>
                </Container>
            </HeadShake>
        </section>
        );
    }
    
}
